<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Company List</h2>
					</div>
				</div>
			</div>
		</div>
		<!-- Table Container Card -->
		<b-card no-body>
			<div class="m-2">
				<!-- Table Top -->
				<b-row class="d-flex justify-content-center align-items-center mx-1">
					<!-- Date picker -->
					<b-col cols="12" md="6">
						<b-row>
							<b-col md="4 p-0 mr-1">
								<label for="example-input">From Date</label>
								<b-form-datepicker
									id="from-date"
									placeholder="Choose a date"
									local="en"
									v-model="search_filter.from_date"
									:date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}"
								/>
							</b-col>
							<b-col md="4 p-0">
								<label for="example-input">To Date</label>
								<b-form-datepicker
									id="to-date"
									placeholder="Choose a date"
									local="en"
									v-model="search_filter.to_date"
									:date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}"
								/>
							</b-col>
							<b-col md="2 d-flex align-items-end">
								<b-button @click="getCompanyList()" variant="primary "> Filter </b-button>
							</b-col>
						</b-row>
					</b-col>
					<!-- Search -->
					<b-col cols="12" md="6" class="mt-2 d-flex justify-content-end align-items-center">
						<div>
							<b-form-input
								type="text"
								class="form-control"
								placeholder="Search"
								@input="getCompanyList()"
								v-model.trim="search_filter.input_field"
								style="border-radius: 4px"
							/>
						</div>
						<b-button
							@click="clearFilter"
							variant="secondary
 			 ml-1"
						>
							Clear
						</b-button>
						<b-button
							:to="{
								name: 'apps-company-add',
							}"
							variant="primary mr-1 ml-2"
						>
							Add
						</b-button>
						<b-button @click="excelDownload()" variant="warning">
							<feather-icon icon="DownloadIcon" size="16" />
						</b-button>
					</b-col>
				</b-row>
			</div>
			<div v-if="company_list && company_list.total > 0">
				<b-table
					responsive
					:items="company_list.data"
					:fields="company_field"
					id="company-table"
					primary-key="id"
					empty-text="No matching records found"
					class="position-relative"
				>
					<template #cell(index)="data">
						<div class="ml-1">
							{{ company_list.from + data.index }}
						</div>
					</template>
					<template #cell(owner)="data">
						<div v-for="item in data.item.owners" :key="item">
							{{ item.user_name }}
						</div>
					</template>
					<!-- Column: Actions -->
					<template #cell(actions)="data">
						<div class="d-flex">
							<!-- <div class="text-nowrap">
								<router-link
									class="text-secondary ml-1"
									:to="{
										path: `/apps/company/hatchery/summary/${data.item.id}`,
									}"
								>
									<feather-icon icon="EyeIcon" size="16"
								/></router-link>
							</div> -->
							<div class="text-nowrap">
								<router-link
									class="text-secondary ml-1"
									:to="{ path: `/apps/company/${data.item.id}` }"
								>
									<feather-icon icon="EditIcon" size="16"
								/></router-link>
							</div>
						</div>
					</template>
				</b-table>
				<div class="mt-2 mr-2" style="margin-bottom: 4rem">
					<b-pagination
						:value="company_list.current_page"
						:total-rows="company_list.total"
						:per-page="company_list.per_page"
						align="right"
						@change="getCompanyList"
					>
					</b-pagination>
				</div>
			</div>
			<!-- vaccine modal -->
			<b-modal id="add-company" title="Add Company" ok-only ok-title="Submit" @ok="addCompany" ref="my-modal">
				<validation-observer ref="simpleRules">
					<b-form ref="form" @submit="addCompany">
						<b-form-group label="Enter  Name" label-for="Enter Company Name ">
							<validation-provider #default="{ errors }" name="Company Name" rules="required|alpha">
								<b-form-input
									name="company_name"
									v-model="companyData.company_name"
									placeholder=""
									maxlength="20"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<b-form-group label="Enter Address" label-for="Enter Company Address ">
							<validation-provider #default="{ errors }" name="Company Address" rules="required|alpha">
								<b-form-input
									name="company_address"
									v-model="companyData.company_address"
									placeholder=""
									maxlength="100"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group label="Enter Email" label-for="Enter Email">
							<validation-provider #default="{ errors }" name="Email" rules="required|email">
								<b-form-input
									name="email_id"
									v-model="companyData.email_id"
									placeholder=""
									type="email"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group label="Add Owner" label-for="Add Owner">
							<div class="form-label-group">
								<validation-provider #default="{ errors }" name="Add Owner" rules="required|alpha">
									<b-form-select
										v-model="selectOwner"
										:options="OwnerList"
										text-field="name"
										value-field="id"
										maxlength="20"
										v-on:change="getSelectedOwner"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</div>
						</b-form-group>
					</b-form>
				</validation-observer>
			</b-modal>
		</b-card>
	</div>
</template>

<script>
import { required, email, alpha, regex } from "@validations";

import vSelect from "vue-select";
import axios from "@/libs/axios";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";

export default {
	components: {
		vSelect,
	},
	data: () => ({
		selectOwner: null,
		OwnerList: null,
		userId: null,
		searchQuery: null,
		companyData: {
			company_name: null,
			company_address: null,
			email_id: null,
			owner: null,
		},
		search_filter: {
			input_field: "",
			to_date: "",
			from_date: "",
		},
		company_field: [
			{ key: "index", label: "S.no" },
			{ key: "company_name", label: "name" },
			{ key: "company_address", label: "address" },
			"email_id",
			"owner",
			"status",
			{
				key: "created_at",
				label: "created at",
				formatter: (value) => {
					return moment(value).format("MMMM Do YYYY");
				},
			},
			"actions",
		],
		required,
		email,
		alpha,
		company_list: [],
	}),
	created: function () {
		this.getCompanyList();
	},

	computed: {
		rows() {
			return this.company_list.length;
		},
	},
	methods: {
		moment() {
			return moment();
		},
		getCompanyList(pageNo = 1) {
			let url = `web/getCompanyList`;
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.company_list = response.data.company_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		addCompany(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					let formData = new FormData();
					this.companyData.user_id = this.userId;
					let form_data = this.objectToFormData(this.companyData, formData);

					axiosIns
						.post(`web/companies`, form_data)
						.then((res) => {
							res;
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `data Successfully Added `,
								},
							});
							this.getCompanyList();
							this.$bvModal.hide("add-company");
						})
						.catch((error) => {
							if (error.response.status == 400) {
								var data = error.response.data;
							} else {
								var data = error.response.data.errors;
							}
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},

		excelDownload() {
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(this.search_filter.from_date).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format("YYYY-MM-DD");
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-company-list`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.getSourceList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getSourceList();
				});
		},
		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getCompanyList();
		},
	},
};
</script>

<style lang="scss" scoped>
#company-table {
	word-break: break-all;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
